<template>
   <section>
       <v-container>
                   <v-carousel
          :continuous="true"
      :show-arrows="true"
      cycle
      hide-delimiter-background
      hide-delimiters
      delimiter-icon="mdi-minus"
      height="300">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        eager
      >
        <v-img :src="item.src" height="100%" eager/>
         
      </v-carousel-item>
 
    </v-carousel>
    <v-col cols="12">
        <span class="dateTxt">{{ $t('new.date')}}</span>
    </v-col>
     <v-col cols="12">
         <h2 class="newsHead">{{ $t('new.head')}}</h2>
    </v-col>
     <v-col cols="12">
            <p>{{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}.
            </p>
                <p>{{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}
                {{ $t('new.caption')}}
            </p>
           
           
    </v-col>
       </v-container>
   </section>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('@/assets/new01.png'),
          },
          {
           src: require('@/assets/new01.png'),
          },
          {
            src: require('@/assets/new01.png'),
          },
          {
            src: require('@/assets/new01.png'),
          },
        ],
      
      }}
  }
</script>
<style lang="scss" scoped>
::v-deep{
    .v-window__prev, .v-window__next {
        background: unset;
    }
}
.dateTxt{
      letter-spacing: 0.51px;
color: #F167A7;
text-transform: uppercase;
font-size: 20px;
}
.dayTxt{
    font-family: 'Teko-SemiBold';
    font-size: 150px;
    color: #F167A7;
}
.newsHead{
  font-family:"Philosopher-regular";

        font-size: 35px;
        line-height: 0.7;
color: #000000;


}
</style>